body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1rem;
}

button {
  background-color: transparent;
  border: none;
}

body,
span,
p,
button,
label,
input,
textarea,
h1,
h2,
h3 {
  font-family: Blinker, sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;*/
}

a:hover,
button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.hidden {
  display: none;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.column {
  display: flex;
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}

.card {
  background-color: white;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 1rem 2rem;
}

h4 {
  color: rgb(206, 206, 206);
  font-size: 1rem;
  margin: 0;
}

h1,
.title {
  color: rgb(61, 42, 203);
  font-size: 3rem;
  font-weight: 800;
  margin: 0;
}

.link, .autolinks a {
  color: rgb(61, 42, 203);
  font-weight: 700;
  text-decoration: none;
}

.link:hover, .autolinks a:hover {
  text-decoration: underline;
}

.button {
  color: white;
  background-color: rgb(61, 42, 203);
  padding: 0.6rem 2.4rem;
  font-size: 1rem;
  letter-spacing: 1.6px;
  border-radius: 999rem;
  font-weight: bold;
}

.button:hover {
  background-color: rgba(61, 42, 203, 0.9);
}

h2 {
  color: black;
  font-weight: 800;
  font-size: 2rem;
  text-decoration: underline;
}

h3 {
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
}

hr {
  width: 100%;
}

select {
  box-sizing: border-box;
  padding: 1rem 0.8rem;
  border-radius: 10px;
  font-size: 1.1rem;
  border: 1px solid rgb(201, 201, 201);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.text-input {
  box-sizing: border-box;
  padding: 1rem 0.8rem;
  border-radius: 10px;
  font-size: 1.1rem;
  border: 1px solid rgb(201, 201, 201);
  width: calc(min(50rem, 100%));
}

.number-input {
  width: 6rem;
}

textarea {
  box-sizing: border-box;
  padding: 1rem 0.8rem;
  border-radius: 10px;
  resize: vertical;
  font-size: 1.1rem;
  border: 1px solid rgb(201, 201, 201);
  width: calc(min(50rem, 100%));
  font-family: Arial;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.column > * {
  margin: 0.3rem 0;
}

.column > :first-child {
  margin-top: 0;
}

.column > :last-child {
  margin-bottom: 0;
}

.row {
  align-items: center;
}

.row > * {
  margin: 0 0.3rem;
}

.row > :first-child {
  margin-left: 0;
}

.row > :last-child {
  margin-right: 0;
}
