@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-Thin.ttf');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-ExtraLight.ttf');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-Light.ttf');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-ExtraBold.ttf');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: Blinker;
    src: url('./Blinker/Blinker-Black.ttf');
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Thin.ttf');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-ExtraLight.ttf');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Light.ttf');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-ExtraBold.ttf');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: Montserrat;
    src: url('./Montserrat/Montserrat-Black.ttf');
    font-style: normal;
    font-weight: 900;
}
